.avatar{
    position: relative;
    background: var(--second-lg);
    width: 40px;
    height: 40px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--primary);
    
    overflow: hidden;
}

.imgAvatar{
    position: absolute;
    width: 40px;
}

