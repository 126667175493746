.dashboard{
    background: var(--background);
}

.dashInner{
    width: 100%;
    height: calc(100vh - 92px);
    display: flex;
}

.dashContent{
    width: 100%;
    padding: 24px 88px;
    display: flex;
    flex-direction: column;
    gap: 24px;

}

.card{
    width: 100%;
    background: var(--card);
    border-radius: 8px;
    padding: 32px;
    box-shadow: var(--shadow-card);
}