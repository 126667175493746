.input,
.inputDisabled{
    position: relative;
    padding: 10px 0px;
    margin: 5px 5px;
}

.input input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.input:hover input {
    border: solid 1px var(--secondary) !important;
    box-shadow: var(--cardShadowLg);
}

.input input:focus {
    outline: none;
    border: solid 1px var(--secondary);
    box-shadow: none;
}

.input label {
    position: absolute;
    top: 20px;
    left: 5px;
    margin-left: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s ease-in-out, background 0s ease-in-out;
    transition: all 0.2s ease-in-out, background 0s ease-in-out;
    color: var(--grey-md);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.input input:focus + label {
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

.input input:not(:placeholder-shown) {
    outline: none;
    border: solid 1px var(--secondary) !important;
    background: transparent !important;
}

.input input:not(:placeholder-shown) + label {
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

.inputIcon {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 0px 12px 0px 10px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.inputIcon img{
    opacity: 0.3;
    width: 24px;
}

.dotsPass{
    font-weight: 900 !important;
}

.redLine input{
  border: solid 1px var(--red) !important;
}

.redLine input:hover{
    border: solid 1px var(--red) !important;
  }

.redLine input:not(:placeholder-shown){
  border: solid 1px var(--red) !important;
}

.redLine input:not(:placeholder-shown) + label{
  color: var(--red) !important;
}

.spanAlert {
    position: absolute;
    bottom: 0px;
    left: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    background: var(--red-lg);
    color: var(--red);
    font: 1.1rem var(--fontGeneral);
    font-weight: 500;
    z-index: 1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.inputDisabled input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--white);
    color: var(--black);
    border: solid 1px var(--secondary);
    box-shadow: none;
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.inputDisabled label {
    position: absolute;
    top: 20px;
    left: -3px;
    padding: 0px 6px;
    margin-left: 10px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
    pointer-events: none;
    z-index: 2;
}

.redLine label{
 color: var(--red) !important;
}

  
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
