.sideBar{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 296px;
    height: calc(100vh - 92px);
    border-right: 1px solid var(--border);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.items{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;
    width: 100%;
    border-bottom: 1px solid var(--border);
}

.levelZ{
    display: flex;
    flex-direction: column;
}

.levelA{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    
    height: 24px;

    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--unactiveli);
}

.levelB{
    padding: 0px 0 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 0px;
    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.levelB a,
.levelA a{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    color: var(--unactiveli);
}

.liActivo{
    color: var(--activeli) !important;
}

.levelBAct{
    padding: 16px 0 0 24px;
    height: auto !important; 
}

.items a svg{
    stroke: var(--icon);
}

.items a:hover, 
.liSideItem:hover{
    color: var(--secondary) !important;
}

.liActivo svg,
.items a:hover svg,
.liSideItem:hover svg{
    stroke: var(--secondary) !important;
}

.liSideItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    background: transparent;
    height: 24px;

    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--grayA);
}

.DropDownMenu{
    stroke: var(--icon);
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.DropDownMenuA{
    stroke: var(--activeli);
    transform: rotate(-180deg);
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
