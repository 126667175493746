.AvatarDrop{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.AvatarDrop .spanName{
    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: var(--grayA);
    padding: 0;
}

.spanName span{
    font-family: var(--fontGeneral);
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--grayA);
}