.btnGeneral {
  padding: 22px 20px;
  margin: 10px 0px;
  border-radius: 8px;
  background: var(--azul-st);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 30px -10px rgba(64, 95, 244, 1);
  color: #fff;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ButtonLoadDiv{
  padding: 0 5px;
}

.Button{
  padding: 22px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  background: var(--primary);
  color: var(--white);
  font: 1.5rem var(--fontHeader), sans-serif;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 0;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Button:hover,
.Button:focus{
  background: var(--primary-lg);
  color: var(--white);
}

.btnLarge{
  width: 100%;
}

.buttonLink{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 16px;
  gap: 8px;

  height: 48px;

  background: var(--secondary);
  box-shadow: 0px 1px 3px rgba(59, 246, 209, 0.4);
  border-radius: 24px;

  font-family: var(--fontGeneral);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--white);
}

