:root {
    --primary: #0a5e5a;
    --secondary: #00b780;
    --black: #141414;
    --primary-lg: #008e83;
    --second-lg: #75dbb9;
    --red: #d63830;
    --red-lg: #fdeeee;
    --grayA: #6A7180;
    --grey: #929bac;
    --grey-md: #b4b4b4;
    --grey-lg: #ececec;
    --fontGeneral: 'Montserrat', sans-serif;
    --fontHeader: 'Titillium Web', sans-serif;
    --background: #F7F8FA;
    --border: #DFE1E6;
    --icon:#BEC2CC;
    --white: #FFFFFF;
    --shadow: ;
    --activeli: #00b780;
    --unactiveli: #6A7180;
    --textColor: #6A7180;
    --card: #FFFFFF;
    --shadow-card: 0px 2px 3px rgba(106, 113, 128, 0.1);
    --cardShadowLg: 0px 1px 10px rgba(59, 246, 209, 0.4);
    --logoColor: #0a5e5a;
}

[data-theme='dark'] {
    --primary: #003f3e;
    --secondary: #00b780;
    --black: #141414;
    --primary-lg: #41a5a2;
    --second-lg: #75dbb9;
    --red: #d63830;
    --red-lg: #fdeeee;
    --grayA: #DFE1E6;
    --grey: #929bac;
    --gris-md: #b4b4b4;
    --grey-lg: #ececec;
    --fontGeneral: 'Montserrat', sans-serif;
    --fontHeader: 'Titillium Web', sans-serif;
    --background: #12151A;
    --border: #2A2E38;
    --icon:#6A7180;
    --white: #FFFFFF;
    --activeli: #FFFFFF;
    --unactiveli: #A1A7B3;
    --textColor: #FFFFFF;
    --card: #1D2129;
    --shadow-card: 0px 2px 3px rgba(0, 0, 0, 0.2);
    --cardShadowLg: 0px 1px 10px rgba(59, 246, 209, 0.4);
    --logoColor: #FFFFFF;
}

.buttonIcon{
    position: relative;
    height: 24px;
    width: 24px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.icon{
    width: 20px;
    height: auto;
    stroke: var(--icon);
}

.flexDiv{
    display: flex;
    justify-content: space-between;
}
  