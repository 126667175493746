@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800;900&family=Titillium+Web:wght@300;400;600;700;900&display=swap');

html {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontSt);
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

a,
a:hover,
a:visited {
  text-decoration: none !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px var(--card) inset !important;
  -webkit-text-fill-color: var(--textColor) !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

::-moz-selection { /* Code for Firefox */
  color: var(--white);
  background: var(--secondary);
}

::selection {
  color: var(--white);
  background: var(--secondary);
}
  
  /* input[type="number"] {
    -webkit-appearance: textfield;
  } */
  
.legalText{
  font: 1.3rem var(--fontGeneral), sans-serif;
  text-align: center;
  color: var(--textColor);
}

.legalText a{
  font-weight: 600;
  color: var(--activeli);
}

.legalText a:hover{
  color: var(--secondary);
}

.formHeader{
  margin: 0;
  line-height: 0;
  font: 3rem var(--fontHeader), sans-serif;
  font-weight: 900;
  text-align: center;
  color: var(--primary);
}

.formSub{
  margin: 0;
  line-height: 0;
  font: 1.4rem var(--fontGeneral), sans-serif;
  font-weight: 400;
  text-align: center;
  color: var(--grey-md);
}

.alertForm{
  width: 100%;
  padding: 10px 5px;
  font: 1.2rem var(--fontGeneral), sans-serif;
  text-align: center;
  
}

.alertForm p{
  background-color: var(--red-lg);
  color: var(--red);
  padding: 5px 5px;
  border-radius: 8px;
}