.navigate{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 10px;
    width: 100%;
    height: 92px;
    border-bottom: 1px solid var(--border);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.navRigth{
    display: flex;
    align-items: center;
    gap: 24px;
}


.logoApp{
    height: 50px;
    fill: var(--logoColor);
}
