.LoginPage{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
}

.LoginContent{
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.LoginDiv{
    border-radius: 20px;
    height: 650px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    background-color: var(--card);
    box-shadow: var(--shadow-card); 
    overflow: hidden;
}

.LoginNotice{
    width: 600px;
    background-color: var(--primary);
}

.LoginForm{
    padding: 100px;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginForm form{
    width: 100%;
}

.LoginBottom{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}